import React, { useEffect, useRef, useState } from 'react';
import { PoseLandmarker, FilesetResolver, DrawingUtils } from '@mediapipe/tasks-vision';
import { Block } from 'baseui/block';

function PoseDetection({ videoElement, onLandmarksDetected }) {
  const canvasRef = useRef(null);
  const [poseLandmarker, setPoseLandmarker] = useState(null);
  const lastVideoTimeRef = useRef(-1);
  const [previousLandmarks, setPreviousLandmarks] = useState(null); // New state for smoothing

  useEffect(() => {
    if (!videoElement) {
      console.log('No video element available');
      return;
    }

    let poseLandmarkerInstance;

    const initializePoseLandmarker = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm'
      );

      poseLandmarkerInstance = await PoseLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath:
            'https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_heavy/float16/1/pose_landmarker_heavy.task',
          delegate: 'GPU',
        },
        runningMode: 'VIDEO',
        numPoses: 1,
        minPoseDetectionConfidence: 0.7, // Increased to reduce noise
        minPoseTrackingConfidence: 0.7, // Increased to reduce noise
        minTrackingConfidence: 0.7,
      });

      console.log('PoseLandmarker initialized');
      setPoseLandmarker(poseLandmarkerInstance);
    };

    initializePoseLandmarker();

    return () => {
      if (poseLandmarkerInstance) {
        poseLandmarkerInstance.close();
      }
    };
  }, [videoElement]);

  useEffect(() => {
    let animationFrameId;
    let isPredicting = false;

    const predictWebcam = () => {
      if (!poseLandmarker || !videoElement || !canvasRef.current) {
        animationFrameId = requestAnimationFrame(predictWebcam);
        return;
      }

      if (videoElement.readyState < 2) {
        animationFrameId = requestAnimationFrame(predictWebcam);
        return;
      }

      if (isPredicting) {
        animationFrameId = requestAnimationFrame(predictWebcam);
        return;
      }

      isPredicting = true;

      const canvasElement = canvasRef.current;
      const canvasCtx = canvasElement.getContext('2d');
      const drawingUtils = new DrawingUtils(canvasCtx);

      canvasElement.width = videoElement.videoWidth;
      canvasElement.height = videoElement.videoHeight;

      if (lastVideoTimeRef.current !== videoElement.currentTime) {
        lastVideoTimeRef.current = videoElement.currentTime;
        const startTimeMs = performance.now();

        poseLandmarker.detectForVideo(videoElement, startTimeMs, (result) => {
          canvasCtx.save();
          canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
          canvasCtx.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

          if (result.landmarks && result.landmarks.length > 0) {
            const smoothingLandmarks = false;
            let smoothedLandmarks = result.landmarks[0];

            if (!smoothingLandmarks) {
              drawingUtils.drawLandmarks(smoothedLandmarks, {
                radius: (data) => {
                  const z = data.z !== undefined ? data.z : 0;
                  return DrawingUtils.lerp(z, -0.15, 0.1, 5, 1);
                },
              });
              drawingUtils.drawConnectors(smoothedLandmarks, PoseLandmarker.POSE_CONNECTIONS);
  
              onLandmarksDetected(smoothedLandmarks);
            } else {
              // Apply smoothing by averaging current and previous landmarks
            if (previousLandmarks) {
              smoothedLandmarks = smoothedLandmarks.map((landmark, index) => {
                const previous = previousLandmarks[index];
                return {
                  x: (landmark.x + previous.x) / 2,
                  y: (landmark.y + previous.y) / 2,
                  z: (landmark.z + previous.z) / 2,
                };
              });
            }

            setPreviousLandmarks(smoothedLandmarks); // Update previous landmarks state

            drawingUtils.drawLandmarks(smoothedLandmarks, {
              radius: (data) => {
                const z = data.z !== undefined ? data.z : 0;
                return DrawingUtils.lerp(z, -0.15, 0.1, 5, 1);
              },
            });
            drawingUtils.drawConnectors(smoothedLandmarks, PoseLandmarker.POSE_CONNECTIONS);

            onLandmarksDetected(smoothedLandmarks);
            }
          } else {
            console.log('No landmarks detected.');
            setPreviousLandmarks(null); // Reset if no landmarks are detected
          }

          canvasCtx.restore();
          isPredicting = false;
          animationFrameId = requestAnimationFrame(predictWebcam);
        });
      } else {
        isPredicting = false;
        animationFrameId = requestAnimationFrame(predictWebcam);
      }
    };

    animationFrameId = requestAnimationFrame(predictWebcam);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [poseLandmarker, videoElement, onLandmarksDetected, previousLandmarks]);

  return (
    <Block
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="20px"
      border="2px solid #ddd"
      borderRadius="10px"
      maxWidth="90%"
      maxHeight="80vh"
      margin="0 auto"
      overrides={{
        Block: {
          style: {
            boxSizing: 'border-box',
          },
        },
      }}
    >
      <canvas ref={canvasRef} style={{ width: '100%', height: 'auto' }} />
    </Block>
  );
}

export default PoseDetection;
