import React, { useState, useEffect, useRef } from 'react';
import PushupTracker from './PushupTracker';
import CommonNavBar from './NavBar';
import { Block } from 'baseui/block';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import CameraSelection from './CameraSelection';
import CameraFeed from './CameraFeed';
import PoseDetection from './PoseDetection';
// import pushupVideo from '../pushups4.mp4';
import pushupVideo from '../pushups5.mp4';
// import pushupVideo from '../pushup2.mp4';

const FACING_MODE = {
  USER: 'user',
  ENVIRONMENT: 'environment',
};

// https://codepen.io/mozmorris/pen/KKwdOPO?editors=0010


const DOWN_THRESHOLD = 120; // Elbow angle in degrees considered as 'down' position
const UP_THRESHOLD = 160; // Elbow angle in degrees considered as 'up' position
const debug = true; // Set to false to disable debug logs

export const PushupTracker2 = ({isDemo}) => {
  const [selectedCamera, setSelectedCamera] = useState('');
  const [facingMode, setFacingMode] = useState('user');
  const [videoElement, setVideoElement] = useState(null);
  const [pushupCount, setPushupCount] = useState(0);

  const videoRef = useRef(null);

  let lastState = 'up';
  const pushupState = useRef('up'); // Possible states: 'up' or 'down'
  const lastDetectedState = useRef(null); // 'up', 'down', or 'in between'
  const stateChangeCounter = useRef(0);

  // Configuration constants
  const STATE_CHANGE_THRESHOLD = 3; // Number of consecutive frames required


  useEffect(() => {
    return () => {
      console.log('PushupTracker2 unmounting');
      // Any cleanup if necessary
    };

  }, []);
  function updatePushupCountInFirestore(count) {
    if (isDemo) {
      return;
    }
    const user = auth.currentUser;
    if (!user) {
      console.error('No authenticated user');
      return;
    }

    const userId = user.uid;
    const today = new Date().toISOString().slice(0, 10);
    const userName = user.displayName || 'Anonymous';
    const userDoc = doc(db, 'pushupCounts', userId);

    setDoc(userDoc, {
      [today]: count,
      name: userName
    }, { merge: true }).then(() => {
      console.log('Firestore updated successfully');
    }).catch(error => {
      console.error('Error updating Firestore: ', error);
    });
  }

function handleLandmarks(landmarks, debug = true) {
  const detectedState = detectPushupState(landmarks, debug);

  if (detectedState === null) {
    // Visibility too low, do not update state
    return;
  }

  if (detectedState === lastDetectedState.current) {
    stateChangeCounter.current += 1;
  } else {
    stateChangeCounter.current = 1;
    lastDetectedState.current = detectedState;
  }

  if (stateChangeCounter.current >= STATE_CHANGE_THRESHOLD) {
    if (pushupState.current !== detectedState) {
      if (detectedState === 'up') {
        // Transition from down to up: complete push-up
        pushupState.current = 'up';
        setPushupCount((prevCount) => {
          const newCount = prevCount + 1;
          updatePushupCountInFirestore(newCount);
          if (debug) console.log('Push-up count incremented to', newCount);
          return newCount;
        });
      } else if (detectedState === 'down') {
        // Transition from up to down: start of a push-up
        pushupState.current = 'down';
        if (debug) console.log('Push-up state changed to down');
      }
      // Reset state change counter after a confirmed state change
      stateChangeCounter.current = 0;
    }
  }
}


  useEffect(() => {
    if (videoRef.current) {
      setVideoElement(videoRef.current);

      // Ensure the video plays automatically
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.log('Auto-play was prevented:', error);
        });
      }
    }
  }, [videoRef]);

  return (
    <Block
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    padding="scale400"
    width="100%"
    height="100%"
    overrides={{
      Block: {
        style: {
          boxSizing: 'border-box',
        },
      },
    }}
  >
      {!isDemo && (<CommonNavBar />)}

      <Block
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        border="2px solid #ddd"
        borderRadius="10px"
        padding="10px"
        width="100%" // Ensure it spans the full width of the parent
        height="100%" // Ensure it spans the full height of the parent
        margin="20px auto"
      >
        <CameraSelection
          onCameraChange={setSelectedCamera}
          onFacingModeChange={setFacingMode}
        />

         <CameraFeed
          selectedCamera={selectedCamera}
          facingMode={facingMode}
          onCameraReady={(videoElement) => {
            videoRef.current = videoElement;
            setVideoElement(videoElement); // Ensure the videoElement is set
          }} 
        /> 
      {/* <video
            ref={videoRef}
            src={pushupVideo}
            autoPlay
            loop
            muted
            controls // Optional: remove if you don't want controls
            style={{ display: 'none' }} // Hide the video element
      /> */}
        {videoElement && (
          <PoseDetection videoElement={videoElement} onLandmarksDetected={handleLandmarks} />
        )}

        <Block 
          marginTop="10px" 
          fontSize="18px" 
          textAlign="center"
        >
          Pushups Counted: {pushupCount}
        </Block>
      </Block>
    </Block>
  );
};
function getAverageVisibilityScore(landmarks, debug = false) {
  let totalScore = 0;
  let totalLandmarks = 0;
  for (let i = 0; i < landmarks.length; i++) {
    if (landmarks[i].visibility !== undefined) {
      totalScore += landmarks[i].visibility;
      totalLandmarks++;
    } else {
      return 1;
    }
  }
  if (totalLandmarks === 0) { 
    return 0;
  }
  const average = totalScore / totalLandmarks;
  if (debug) {
    console.log('Average visibility score:', average);
  }
  return average;
}

function detectPushupState(landmarks, debug = false) {
  // Define constants for thresholds
  const DOWN_THRESHOLD = 100; // Adjust as needed
  const UP_THRESHOLD = 130; // Adjust as needed
  const VISIBILITY_THRESHOLD = 0.5;

  const leftShoulder = landmarks[11];
  const rightShoulder = landmarks[12];
  const leftElbow = landmarks[13];
  const rightElbow = landmarks[14];
  const leftWrist = landmarks[15];
  const rightWrist = landmarks[16];

  // Calculate visibility scores for each arm
  const leftArmLandmarks = [leftShoulder, leftElbow, leftWrist];
  const rightArmLandmarks = [rightShoulder, rightElbow, rightWrist];

  console.log('leftArmLandmarks:', leftArmLandmarks);
  console.log('rightArmLandmarks:', rightArmLandmarks);
  const leftArmVisibility = getAverageVisibilityScore(leftArmLandmarks, debug);
  const rightArmVisibility = getAverageVisibilityScore(rightArmLandmarks, debug);

  if (debug) {
    console.log('Left arm visibility:', leftArmVisibility);
    console.log('Right arm visibility:', rightArmVisibility);
  }

  let chosenArm;
  let elbowAngle;

  // Choose the arm with higher visibility score
  if (leftArmVisibility >= rightArmVisibility && leftArmVisibility >= VISIBILITY_THRESHOLD) {
    chosenArm = 'left';
    elbowAngle = calculateAngle(leftShoulder, leftElbow, leftWrist, debug);
    if (debug) console.log('Using left arm for detection.');
  } else if (rightArmVisibility > leftArmVisibility && rightArmVisibility >= VISIBILITY_THRESHOLD) {
    chosenArm = 'right';
    elbowAngle = calculateAngle(rightShoulder, rightElbow, rightWrist, debug);
    if (debug) console.log('Using right arm for detection.');
  } else {
    if (debug) console.log('No arm has sufficient visibility.');
    return null; // Neither arm has sufficient visibility
  }

  if (debug) {
    console.log(`${chosenArm.charAt(0).toUpperCase() + chosenArm.slice(1)} elbow angle:`, elbowAngle);
  }

  if (elbowAngle < DOWN_THRESHOLD) {
    if (debug) console.log('Detected push-up state: down');
    return 'down';
  } else if (elbowAngle > UP_THRESHOLD) {
    if (debug) console.log('Detected push-up state: up');
    return 'up';
  } else {
    if (debug) console.log('Push-up state: in between');
    return 'in between';
  }
}

function calculateAngle(pointA, pointB, pointC, debug = false) {
  const vectorBA = {
    x: pointA.x - pointB.x,
    y: pointA.y - pointB.y,
    z: pointA.z - pointB.z,
  };
  const vectorBC = {
    x: pointC.x - pointB.x,
    y: pointC.y - pointB.y,
    z: pointC.z - pointB.z,
  };

  const dotProduct =
    vectorBA.x * vectorBC.x + vectorBA.y * vectorBC.y + vectorBA.z * vectorBC.z;
  const magnitudeBA = Math.hypot(vectorBA.x, vectorBA.y, vectorBA.z);
  const magnitudeBC = Math.hypot(vectorBC.x, vectorBC.y, vectorBC.z);

  const cosineAngle = dotProduct / (magnitudeBA * magnitudeBC);
  const clampedCosine = Math.min(Math.max(cosineAngle, -1), 1); // Clamp value between -1 and 1
  const angleRadians = Math.acos(clampedCosine);
  const angleDegrees = (angleRadians * 180) / Math.PI;

  if (debug) {
    console.log('Angle at point', pointB, 'is', angleDegrees, 'degrees');
  }

  return angleDegrees;
}


// };
// function getAverageVisibilityScore(landmarks, debug = false) {
//   let totalScore = 0;
//   let totalLandmarks = 0;
//   for (let i = 0; i < landmarks.length; i++) {
//     if (landmarks[i].visibility !== undefined) {
//       totalScore += landmarks[i].visibility;
//       totalLandmarks++;
//     }
//   }
//   const average = totalScore / totalLandmarks;
//   if (debug) {
//     console.log('Average visibility score:', average);
//   }
//   return average;
// }

// function isHorizontallyAligned(landmarks, tolerance = 0.1, debug = false) {
//   const leftShoulder = landmarks[11];
//   const rightShoulder = landmarks[12];
//   const leftHip = landmarks[23];
//   const rightHip = landmarks[24];

//   // Calculate the absolute difference in y-coordinates of key landmarks
//   const shoulderDifference = Math.abs(leftShoulder.y - rightShoulder.y);
//   const hipDifference = Math.abs(leftHip.y - rightHip.y);

//   // Check if both shoulders and hips are within the horizontal tolerance
//   const isAligned = shoulderDifference <= tolerance && hipDifference <= tolerance;

//   if (debug) {
//     console.log('Shoulder y-difference:', shoulderDifference);
//     console.log('Hip y-difference:', hipDifference);
//     console.log('Is horizontally aligned:', isAligned);
//   }

//   return isAligned;
// }

// function detectPushupState(landmarks, prevLeftAngles, prevRightAngles, debug = false) {
//   const leftShoulder = landmarks[11];
//   const rightShoulder = landmarks[12];
//   const leftElbow = landmarks[13];
//   const rightElbow = landmarks[14];
//   const leftWrist = landmarks[15];
//   const rightWrist = landmarks[16];

//   const averageVisibility = getAverageVisibilityScore([
//     leftShoulder,
//     rightShoulder,
//     leftElbow,
//     rightElbow,
//     leftWrist,
//     rightWrist,
//   ], debug);

//   if (averageVisibility < 0.5) {
//     if (debug) console.log('Low visibility, unable to detect state.');
//     return null; // Low visibility, unable to detect state
//   }

//   // Adjust tolerance based on camera angle flexibility
//   const alignmentTolerance = 0.2; // Increase tolerance to account for camera angle variations
//   if (!isHorizontallyAligned(landmarks, alignmentTolerance, debug)) {
//     if (debug) console.log('Person is not horizontally aligned.');
//     return null; // Person is not horizontally aligned
//   }

//   const leftElbowAngle = calculateAngle(leftShoulder, leftElbow, leftWrist, debug);
//   const rightElbowAngle = calculateAngle(rightShoulder, rightElbow, rightWrist, debug);

//   if (debug) {
//     console.log('Left elbow angle:', leftElbowAngle);
//     console.log('Right elbow angle:', rightElbowAngle);
//   }

//   // Push the new angles to history
//   prevLeftAngles.push(leftElbowAngle);
//   prevRightAngles.push(rightElbowAngle);

//   // Smooth angles using moving average
//   const smoothedLeftElbowAngle = calculateMovingAverage(prevLeftAngles, 5, debug);
//   const smoothedRightElbowAngle = calculateMovingAverage(prevRightAngles, 5, debug);

//   if (debug) {
//     console.log('Smoothed left elbow angle:', smoothedLeftElbowAngle);
//     console.log('Smoothed right elbow angle:', smoothedRightElbowAngle);
//   }

//   const upThreshold = 120; // Threshold angle for "up" position
//   const downThreshold = 90; // Threshold angle for "down" position

//   if (
//     smoothedLeftElbowAngle <= downThreshold ||
//     smoothedRightElbowAngle <= downThreshold
//   ) {
//     if (debug) console.log('Detected pushup state: down');
//     return 'down';
//   } else if (
//     smoothedLeftElbowAngle >= upThreshold &&
//     smoothedRightElbowAngle >= upThreshold
//   ) {
//     if (debug) console.log('Detected pushup state: up');
//     return 'up';
//   }

//   if (debug) console.log('Pushup state: none');
//   return null;
// }

// function calculateMovingAverage(arr, windowSize, debug = false) {
//   if (arr.length < windowSize) {
//     if (debug) {
//       console.log(
//         'Not enough data points for moving average. Returning latest value:',
//         arr[arr.length - 1]
//       );
//     }
//     return arr[arr.length - 1]; // Not enough data points
//   }
//   const start = arr.length - windowSize;
//   const windowData = arr.slice(start);
//   const average =
//     windowData.reduce((sum, value) => sum + value, 0) / windowSize;
//   if (debug) {
//     console.log(`Moving average over last ${windowSize} values:`, average);
//   }
//   return average;
// }

// function calculateAngle(pointA, pointB, pointC, debug = false) {
//   const vectorBA = {
//     x: pointA.x - pointB.x,
//     y: pointA.y - pointB.y,
//   };
//   const vectorBC = {
//     x: pointC.x - pointB.x,
//     y: pointC.y - pointB.y,
//   };

//   const dotProduct =
//     vectorBA.x * vectorBC.x + vectorBA.y * vectorBC.y;
//   const magnitudeBA = Math.sqrt(
//     vectorBA.x * vectorBA.x + vectorBA.y * vectorBA.y
//   );
//   const magnitudeBC = Math.sqrt(
//     vectorBC.x * vectorBC.x + vectorBC.y * vectorBC.y
//   );

//   const cosineAngle = dotProduct / (magnitudeBA * magnitudeBC);

//   // Ensure the cosine value is within the valid range [-1, 1]
//   const clampedCosine = Math.min(Math.max(cosineAngle, -1), 1);

//   const angleRadians = Math.acos(clampedCosine);
//   const angleDegrees = angleRadians * (180 / Math.PI);

//   if (debug) {
//     console.log('Angle at point', pointB, 'is', angleDegrees, 'degrees');
//   }

//   return angleDegrees;
// }


// // export default PushupCounter